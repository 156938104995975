import { useQuery, useMutation } from 'react-query'
import API from '.'
import { serializeQuery } from './utils'

const ENDPOINT = '/api/user-settings'
const QUERY_KEY = 'user-settings'

export const getUserSettings = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'list', query],
    async () => (await API.get(serializeQuery(ENDPOINT, query))).data,
    config
  )

export const updateUserSettings = (config = {}) =>
  useMutation(async (newData) => await API.patch(ENDPOINT, newData), config)
