import React, { useEffect } from 'react'
import { Typography, Box, Button, Card, CardContent, Container } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import { useForm } from 'react-hook-form'
import { getUserSettings, updateUserSettings } from '@src/shared/api/user-settings'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useSnackbar } from 'notistack'
import ReferralDetails from './referralCampaign/ReferralDetails'
import RebateStats from './referralCampaign/RebateStats'
import { enableReferralCampaign } from 'shared/config/regional-configs'
import { useAuth } from '@src/shared/hooks/context/AuthContext'
import useScrollToTop from '@src/shared/utils/scrollToTop'
import { DEALER_ROLE, HTS_DEALER_ROLE } from 'shared/constants/roles'

export const USER_SETTING_ROLE = [DEALER_ROLE, HTS_DEALER_ROLE]

const UserSettings = () => {
  useScrollToTop()
  const { country, user } = useAuth()

  if (!USER_SETTING_ROLE.includes(user.role)) return null

  const { enqueueSnackbar } = useSnackbar()
  const { register, handleSubmit, setValue, watch } = useForm()
  const { data } = getUserSettings()
  const updateUserSettingsMutation = updateUserSettings()

  useEffect(() => {
    if (data) setValue('isReceiveEmail', data.isReceiveEmail)
  }, [data])

  const onSubmit = async (formData) => {
    try {
      await updateUserSettingsMutation.mutateAsync(formData)
      enqueueSnackbar('Settings updated successfully', { variant: 'success' })
    } catch {
      enqueueSnackbar('Failed to update settings', { variant: 'error' })
    }
  }

  return (
    <Container style={{ padding: 0, margin: 0 }}>
      {enableReferralCampaign[country] && (
        <>
          <RebateStats />
          <ReferralDetails />
        </>
      )}
      <Card>
        <CardContent>
          <Typography variant='h6' gutterBottom>
            User Settings
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControlLabel
              control={
                <Checkbox
                  {...register('isReceiveEmail')}
                  checked={watch('isReceiveEmail', false)}
                />
              }
              label='Receive Order Confirmation Emails'
            />
            <Box display='flex' justifyContent='flex-end' mt={2}>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                disabled={updateUserSettingsMutation.isLoading}
              >
                Save
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Container>
  )
}

export default UserSettings
