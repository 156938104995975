const MALAYSIA = 'MY'
const SINGAPORE = 'SG'

const DEFAULT_COUNTRY =
  (typeof APP_REGION === 'undefined'
    ? typeof process !== 'undefined'
      ? process?.env?.APP_REGION
      : MALAYSIA
    : APP_REGION) || MALAYSIA

const ORIGINS_MAP = {
  [MALAYSIA]: {
    originsDict: {
      IN: 'India',
      RO: 'Romania',
      TH: 'Thailand',
      US: 'United States',
      ID: 'Indonesia',
      JP: 'Japan',
      PL: 'Poland',
      VN: 'Vietnam',
      CN: 'China',
      MY: 'Malaysia',
      DE: 'Germany',
      CZ: 'Czech',
      PT: 'Portugal',
      SK: 'Slovakia',
      ZA: 'South Africa',
      SL: 'Sierra Leone',
      LU: 'Luxembourg',
      KR: 'Korea',
      HU: 'Hungary',
      FR: 'France',
      IT: 'Italy',
      ES: 'Spain',
      BR: 'Brazil',
      MX: 'Mexico',
      GB: 'United Kingdom',
      GE: 'Georgia',
      RS: 'Serbia',
      SI: 'Slovenia',
    },
    altOrigins: {
      US: 'USA',
      ES: 'SP',
      PL: 'PO',
      ZA: 'SA',
    },
  },
  [SINGAPORE]: {
    originsDict: {
      CN: 'China',
      KR: 'Korea',
      TH: 'Thailand',
      PL: 'Poland',
      MX: 'Mexico',
      HU: 'Hungary',
      RO: 'Romania',
      ZA: 'South Africa',
      SI: 'Slovenia',
      MY: 'Malaysia',
      IT: 'Italy',
      TR: 'Turkey',
      DE: 'Germany',
      FR: 'France',
      JP: 'Japan',
      ES: 'Spain',
      PR: 'Portugal',
      GB: 'Great Britain',
      EU: 'Europe',
      CZ: 'Czech',
      US: 'USA',
      LU: 'Luxembourg',
      IN: 'India',
      SK: 'Slovakia',
      ID: 'Indonesia',
      RU: 'Russia',
      TW: 'Taiwan',
      RS: 'Serbia',
    },
    altOrigins: {
      CN: 'CHN',
      KR: 'KOR',
      TH: 'THA',
      PL: 'POL',
      MX: 'MEX',
      HU: 'HUN',
      RO: 'ROU',
      ZA: 'SAF',
      SI: 'SLO',
      MY: 'MAL',
      IT: 'ITA',
      TR: 'TUR',
      DE: 'GER',
      FR: 'FRA',
      JP: 'JAP',
      ES: 'ESP',
      PR: 'POR',
      GB: 'GBR',
      EU: 'EUR',
      CZ: 'CZE',
      US: 'USA',
      LU: 'LUX',
      IN: 'IND',
      SK: 'SVK',
      ID: 'IDN',
      RU: 'RUS',
      TW: 'TAI',
      RS: 'SRB',
      SI: 'SVN',
    },
  },
}

const getOriginName = (origin, country = DEFAULT_COUNTRY) =>
  ORIGINS_MAP[country].originsDict[origin] ||
  ORIGINS_MAP[country].originsDict[
    Object.keys(ORIGINS_MAP[country].altOrigins).find(
      (key) => ORIGINS_MAP[country].altOrigins[key] === origin
    )
  ]

const queryOrigins = (origins, country = DEFAULT_COUNTRY) => {
  const originList = origins.slice()
  for (const origin of origins) {
    if (ORIGINS_MAP[country].altOrigins[origin]) {
      originList.push(ORIGINS_MAP[country].altOrigins[origin])
    }
  }
  return originList
}

const getOriginCode = (origin, country = DEFAULT_COUNTRY) =>
  Object.keys(ORIGINS_MAP[country].altOrigins).find(
    (key) => ORIGINS_MAP[country].altOrigins[key] === origin
  ) || origin

const getOriginCodes = (origins, country = DEFAULT_COUNTRY) => {
  const { originsDict, altOrigins } = ORIGINS_MAP[country]

  return origins.flatMap((origin) => {
    const key = Object.keys(originsDict).find((k) => originsDict[k] === origin)
    return key ? [key, altOrigins[key]].filter(Boolean) : [origin]
  })
}

module.exports = {
  MALAYSIA,
  SINGAPORE,
  ORIGINS_MAP,
  getOriginName,
  getOriginCode,
  queryOrigins,
  getOriginCodes,
  DEFAULT_COUNTRY,
}
