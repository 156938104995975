import { useQuery, useMutation } from 'react-query'
import API from '.'

const ENDPOINT = '/api/cart'
const QUERY_KEY = 'cart'

export const useGetCart = (config = {}) =>
  useQuery([QUERY_KEY, 'detail'], async () => (await API.get(ENDPOINT)).data, config)

export const usePostCart = (config = {}) =>
  useMutation((newData) => API.post(ENDPOINT, newData), config)

export const usePatchCart = (config = {}) =>
  useMutation((updatedData) => API.patch(ENDPOINT, updatedData), config)

export const usePatchCartSalesDealer = (config = {}) =>
  useMutation((updatedData) => API.patch(`${ENDPOINT}/customer`, updatedData), config)

export const useDeleteCart = (config = {}) =>
  useMutation((id) => API.delete(`${ENDPOINT}/${id}`), config)

export const useGetStockQuantity = (config = {}) =>
  useQuery([QUERY_KEY, 'qty'], async () => (await API.get(`${ENDPOINT}/qty`)).data, config)

export const usePostSalesCustomDiscount = (config = {}) =>
  useMutation((newData) => API.post(`${ENDPOINT}/sales-discount`, newData), config)
